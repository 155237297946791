import styles from './index.module.scss'

const NewBadge = () => {
  return (
    <span className={styles.container}>
      <svg width="28" height="9" viewBox="0 0 28 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.8292 8.23857L16.082 0.015625H17.8172L18.2357 6.11235L21.168 0.015625H22.5801L23.1541 6.11235L25.9428 0.015625H27.678L23.7341 8.23857H21.8553L21.3306 2.81143L18.708 8.23857H16.8292Z"
          fill="#333333"
        />
        <path
          d="M10.6865 6.68796H14.2167L13.9153 8.23857H8.73365L10.332 0.015625H15.4539L15.1525 1.56624H11.6821L11.3418 3.31655H14.5131L14.2162 4.84367H11.045L10.6865 6.68796Z"
          fill="#333333"
        />
        <path
          d="M7.12786 0.015625H8.77929L7.18092 8.23857H5.92439L3.3116 3.21083L2.33431 8.23857H0.682871L2.28125 0.015625H3.53778L6.15285 5.03162L7.12786 0.015625Z"
          fill="#333333"
        />
      </svg>
    </span>
  )
}

export default NewBadge
